import { type FunctionComponent } from 'react';
import ContactLink from '../components/ui/ContactLink';
import '../style/contacts.css';

const Contacts: FunctionComponent = () => {
  return (
    <main className="main-content">
      <h1 className="large-header">
        <span className="underline-animated-text">Contact me</span>
      </h1>
      <div className="contacts-container">
        <ContactLink
          href="mailto:victorpatrin@gmail.com"
          text="victorpatrin@gmail.com"
        />
        <ContactLink href="https://github.com/Umlen" text="GitHub" />
        <ContactLink
          href="https://www.codewars.com/users/Umlen"
          text="Codewars"
        />
        <ContactLink
          href="https://www.linkedin.com/in/victor-patrin-255899232/"
          text="LinkedIn"
        />
      </div>
    </main>
  );
};

export default Contacts;
