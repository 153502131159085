import { type FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTheme } from '../hooks/hooks';
import Header from './Header';
import Home from '../pages/Home';
import Projects from '../pages/Projects';
import Contacts from '../pages/Contacts';
import Footer from './Footer';
import '../style/app.css';

const App: FunctionComponent = () => {
  const [isDarkModeOn, changeTheme] = useTheme();

  return (
    <div className="container">
      <Header isDarkModeOn={isDarkModeOn} themeToggler={changeTheme} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
