import { type FunctionComponent } from 'react';
import '../style/footer.css';

const Footer: FunctionComponent = () => {
  return (
    <footer className="footer">
      <p>Made by Viktor P. © 2023</p>
    </footer>
  );
};

export default Footer;
