import { type FunctionComponent } from 'react';
import { type LinkType } from '../../types/types';

const LinkAsButton: FunctionComponent<LinkType> = ({ href, text }) => {
  return (
    <a
      href={href}
      target="_blank"
      className="basic-btn hover-low-opacity project-btns"
      rel="noreferrer"
    >
      {text}
    </a>
  );
};

export default LinkAsButton;
