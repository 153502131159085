import { type FunctionComponent } from 'react';

interface TechStackItemProps {
  tech: string;
}

const TechStackItem: FunctionComponent<TechStackItemProps> = ({ tech }) => {
  return <li className="underline-text project-tech-list-item">{tech}</li>;
};

export default TechStackItem;
