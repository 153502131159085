import { type FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import lightLogo from '../images/logo/light-logo.svg';
import darkLogo from '../images/logo/dark-logo.svg';
import moon from '../images/icons/moon.svg';
import sun from '../images/icons/sun.svg';
import '../style/header.css';

interface HeaderProps {
  isDarkModeOn: boolean;
  themeToggler: () => void;
}

const Header: FunctionComponent<HeaderProps> = (props) => {
  const { isDarkModeOn, themeToggler } = props;

  return (
    <header className="header">
      <object
        type="image/svg+xml"
        className="logo"
        title="logo"
        data={isDarkModeOn ? darkLogo : lightLogo}
      />
      <nav className="nav-menu">
        <Link to="/" className="basic-link nav-links">
          Home
        </Link>
        <Link to="/projects" className="basic-link nav-links">
          Projects
        </Link>
        <Link to="/contacts" className="basic-link nav-links">
          Contacts
        </Link>
      </nav>
      <img
        alt=""
        className="theme-toggle-icon"
        src={isDarkModeOn ? sun : moon}
        onClick={themeToggler}
      />
    </header>
  );
};

export default Header;
