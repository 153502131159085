import { type FunctionComponent } from 'react';
import { type ProjectType } from '../types/types';
import TechStackItem from './TechStackItem';
import LinkAsButton from './ui/LinkAsButton';
import '../style/project-preveiw.css';

interface ProjectPreviewType {
  project: ProjectType;
}

const ProjectPreview: FunctionComponent<ProjectPreviewType> = ({ project }) => {
  const { name, descr, code, demo, images, techStack } = project;

  return (
    <div className="project-contaier">
      <img
        src={window.innerWidth < 700 ? images.mobileImg : images.desktopImg}
        alt={`${name} screenshot`}
        className="project-img"
      />
      <div className="project-descr-wrapper">
        <h3 className="mid-header project-title">{name}</h3>
        <p className="project-desrc">{descr}</p>
        <ul className="project-tech-list">
          {techStack.split(' ').map((tech, key) => (
            <TechStackItem key={key} tech={tech} />
          ))}
        </ul>
        <LinkAsButton href={code} text="Code" />
        <LinkAsButton href={demo} text="Live" />
      </div>
    </div>
  );
};

export default ProjectPreview;
