import { type FunctionComponent, useState, useEffect } from 'react';
import { type ProjectType } from '../types/types';
import { useFetching } from '../hooks/hooks';
import getProjects from '../api/getProjects';
import ProjectPreview from '../components/ProjectPreview';

const Projects: FunctionComponent = () => {
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [fetchProjects, isLoading, loadingError] = useFetching(async () => {
    const response = await getProjects();
    setProjects(response);
  });

  useEffect(() => {
    void fetchProjects();
    document.documentElement.scrollTo(0, 0);
  }, []);

  return (
    <main className="main-content">
      <h1 className="large-header">
        <span className="underline-animated-text">Projects</span>
      </h1>
      {isLoading && <h2>Loading...</h2>}
      {loadingError && <h2>{`${loadingError}`}</h2>}
      {projects.map((project) => {
        return <ProjectPreview key={project._id} project={project} />;
      })}
    </main>
  );
};

export default Projects;
