import { type ProjectType } from '../types/types';

async function getProjects(): Promise<any> {
  const url = 'https://new-portfolio-api-ten.vercel.app/projects';

  const response = await fetch(url);
  const projects: ProjectType[] = await response.json();

  return projects.reverse();
}

export default getProjects;
