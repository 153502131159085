import { type FunctionComponent, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { type ProjectType } from '../types/types';
import { useFetching } from '../hooks/hooks';
import getProjects from '../api/getProjects';
import ProjectPreview from '../components/ProjectPreview';
import ContactLink from '../components/ui/ContactLink';
import '../style/home.css';

const Home: FunctionComponent = () => {
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [fetchProjects, isLoading, loadingError] = useFetching(async () => {
    const response = await getProjects();
    setProjects(response.slice(0, 3));
  });

  useEffect(() => {
    void fetchProjects();
  }, []);

  return (
    <main className="main-content">
      <h1 className="large-header">Hello, I&apos;m Viktor.</h1>
      <h2 className="big-header">
        I&apos;m a{' '}
        <span className="underline-animated-text">Frontend Developer</span>
      </h2>
      <section className="home-section about-section">
        <h3 className="marginBottom1em">
          I really enjoy making simple and beautiful things for users&apos;
          convenience.
        </h3>
        <ul className="about-list">
          <li className="about-list-item">
            <span className="underline-text">Skills:</span> JavaScript, React,
            TypeScript, Next.js, Redux Toolkit, MobX, Node.js, Express, MongoDB,
            HTML, CSS, SCSS.
          </li>
          <li className="about-list-item">
            <span className="underline-text">Interests:</span> reading, cooking,
            painting, board games, video games.
          </li>
        </ul>
      </section>
      <section className="home-section">
        <h2 className="big-header">
          <span className="underline-text">Latest Projects</span>
        </h2>
        {isLoading && <h2>Loading...</h2>}
        {loadingError && <h2>{`${loadingError}`}</h2>}
        {projects.map((project) => {
          return <ProjectPreview key={project._id} project={project} />;
        })}
        <Link
          to="/projects"
          className="basic-btn all-projects-btn hover-low-opacity"
        >
          View all projects
        </Link>
      </section>
      <section className="home-section email-section">
        <h2 className="mid-header">
          <span className="underline-text">Email me</span>
        </h2>
        <ContactLink
          href="mailto:victorpatrin@gmail.com"
          text="victorpatrin@gmail.com"
        />
      </section>
    </main>
  );
};

export default Home;
