import { type FunctionComponent } from 'react';
import { type LinkType } from '../../types/types';

const ContactLink: FunctionComponent<LinkType> = ({ href, text }) => {
  return (
    <a
      href={href}
      target="_blank"
      className="basic-link hover-low-opacity contact-link"
      rel="noreferrer"
    >
      {text}
    </a>
  );
};

export default ContactLink;
