import { useEffect, useState } from 'react';
import { themeCheck } from '../utils/utils';

type UseFetchingType = (
  callback: () => Promise<any>,
) => [() => Promise<any>, boolean, string];

export const useTheme = (): [boolean, () => void] => {
  const [isDarkModeOn, setIsDarkModeOn] = useState(themeCheck());

  function changeTheme(): void {
    setIsDarkModeOn((prevDarkMode) => !prevDarkMode);
  }

  useEffect(() => {
    if (isDarkModeOn) {
      document.body.classList.remove('light-theme');
      document.body.classList.add('dark-theme');
      localStorage.setItem('VPDarkTheme', JSON.stringify(true));
    } else {
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
      localStorage.setItem('VPDarkTheme', JSON.stringify(false));
    }
  }, [isDarkModeOn]);

  return [isDarkModeOn, changeTheme];
};

export const useFetching: UseFetchingType = (callback) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  async function fetching(): Promise<any> {
    try {
      setIsLoading(true);
      await callback();
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }

  return [fetching, isLoading, error];
};
